<template>
  <div class="upload-demo">
    <el-upload
      ref="upload"
      :drag="drag"
      :data="data"
      :action="action"
      :auto-upload="autoUpload"
      :before-upload="beforeUpload"
      :http-request="uploadFile"
      :multiple="multiple"
      :on-remove="handleRemove"
      :limit="limit"
      :disabled="disabled"
      :on-exceed="handleExceed"
      :file-list="formUploadFileList"
      :on-success="success"
      :on-error="error"
    >
      <!-- <el-button
      slot="trigger"
      :disabled="disabled"
      :on-exceed="handleExceed"
      :before-remove="beforeRemove"
      :file-list="formUploadFileList"
      :on-success="success"
      :on-error="error"
    >
    </el-button> -->
      <el-button
        slot="trigger"
        :disabled="disabled"
        size="small"
        type="primary"
        v-if="!drag"
      >
        <!-- 点击上传 -->
        {{ $t("label.file.uploadfiles") }}
      </el-button>
      <i class="el-icon-upload" v-if="drag"></i>
      <div
        class="el-upload__text"
        :class="{ active: disabled }"
        v-if="drag"
        v-html="$t('vue_label_commonobjects_detail_dragtoupload')"
      ></div>
      <div class="disabledIntro" v-show="disabled">
        <i class="el-icon-lock"></i>
        <span>{{ $t("front-lighting-module-v1-detail-file-uploadInfo") }}</span>
      </div>
      <!-- <div class="el-upload__tip"
          slot="tip">只能上传{{fileType.join('/')}}文件，且不超过{{size / 1024}}Mb</div> -->
      <div class="el-upload__tip" slot="tip">
        <!-- 文件大小不超过{{size / 1024}}Mb -->
        {{ $t("vue_label_file_size_limit", { size: defaultFileSize }) }}
      </div>
    </el-upload>

    <!-- 上传进度条 -->
    <el-progress
      v-show="percentage > 0 && percentage < 100"
      class="progreccBox"
      color="#409eff"
      :percentage="percentage"
    ></el-progress>
  </div>
</template>

<script type="text/ecmascript-6">
import { upload, queryLimit } from "./api";

export default {
  name: "Upload",
  props: {
    // 文件相关列表主记录id
    mainId: {
      type: String,
      default: "",
    },
    fileName: {
      type: String,
      default: "",
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean, // 是否禁用
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    callback: {
      type: [Function, undefined],
      default: undefined,
    },
    action: {
      // 请求地址
      type: String,
      default: "",
    },
    fileList: {
      type: Object,
      default: () => {
        return {
          templateItemId: "",
          name: "", // 材料名称
          tip: "", // 提示信息
          attachId: null, // 清单项模板Id
          attachName: null, // 清单项模板名称
          fileSize: 0,
          fileName: "", // 已经上传的文件的名称,
          needUpload: true, // 需要用户上传，大多材料需要上传，采集表由系统生成不用上传
          fileFormat: [], // 上传的文件类型
        };
      },
    },
    multiple: {
      type: Boolean, // 是否可多选
      default: true,
    },
    data: {
      type: Object, // 额外参数
      default: () => {
        return {};
      },
    },
    identification: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      formUploadFileList: this.fileList.fileList, // 上传列表合集
      form: null,
      defaultFileSize: 10,
      size: 104857600,
      percentage: 0, // 上传文件进度
    };
  },
  created() {
    // 文件-获取文件大小限制
    this.queryLimitText();
  },
  methods: {
    // 获取文件展示大小
    queryLimitText() {
      queryLimit({ type: "file" }).then((res) => {
        this.defaultFileSize = Number(res.data.slice(0, res.data.length - 2));
        this.size =
          Number(res.data.slice(0, res.data.length - 2)) * 1024 * 1024;
      });
    },
    success() {
      this.$emit("success", arguments);
    },
    error() {
      this.$emit("error", arguments);
    },
    beforeRemove(file, fileList) {
      this.percentage = 0;
      // 不允许删除,与文件格式或大小校验失败阻止文件上传冲突
      // return false

      // this.$emit('beforeRemove')
    },
    handleRemove(file, fileList) {
      this.percentage = 0;
    },
    // 文件上传前执行的事件
    beforeUpload(file) {
      let size = file.size;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      // if (this.fileName !== '' && file.name.split('.')[0] !== this.fileName) {
      //   this.$message.error('文件名必须为' + this.fileName)
      //   return false
      // }
      if (size > this.size) {
        // 附件大小超过{size}M，上传失败
        this.$message.error(
          file.name +
            this.$i18n.t("vue_label_file_size_limit_new", {
              size: this.size / (1024 * 1024),
            })
        );
        return false;
      }
      if (this.fileType.length > 1 && this.fileType.indexOf(type) === -1) {
        this.$message.error(
          this.$i18n.t("vue_label_file_wrong_type", { type: type })
        );
        return false;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("groupid", "");
      formData.append("libid", "");
      formData.append("parentid", "");
      formData.append("parentid", "");
      if (this.identification === "email") {
        formData.append("isFromEmail", 1);
      } else {
        formData.append("isFromEmail", "");
      }
      if (this.mainId !== "") {
        formData.append("recordId", this.mainId);
      }
      /*
       * 获取上传文件时的进度
       * 1.进度条的实现是依靠axios中提供的onUploadProgress函数
       * 2.该函数提供了文件已上传部分的大小progressEvent.loaded、文件总大小progressEvent.total
       * 3.利用这两个数据就可以计算出已经上传文件的进度
       */
      let config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };
      upload(formData, config)
        .then((res) => {
          this.$emit("UploadSuccess", res.data);
        })
        .catch((err) => {
          return false;
        });
    },
    uploadFile(params) {
      let form = new FormData(); // 创建form对象
      form.append("file", params.file); // 通过append向form对象添加数据
      this.$emit("fileChange", params);
      // this.callback && this.callback(form, data)
      // this.formUploadFileList.push({
      //   name: file.name,
      //   status: 'ready',
      //   uid: file.uid,
      //   url: '',
      //   file: file
      // })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `文件个数超出预设，如果需要替换文件，请先删除已上传的文件`
      );
    },
    // 获取上传文件列表
    getUploadFileList() {
      let formData = new FormData();
      let fileList = [];
      if (!this.formUploadFileList) {
        this.formUploadFileList = [];
      }
      this.formUploadFileList.forEach((item, idx) => {
        // 如果文件没有上传 返回列表
        if (item.status === "ready") {
          // 用数组表示是因为需要批量上传的缘故
          formData.append(`${this.fileName}`, item.file);
          fileList.push(item.file);
        }
      });
      return { formData, fileList };
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.progreccBox {
  ::v-deep .el-progress {
    top: -4px;
  }
}
::v-deep .el-icon-close {
  display: none;
}
.active {
  cursor: not-allowed !important;
}
::v-deep .active em {
  color: #adadad !important;
}
.disabledIntro {
  margin-top: 10px;
  cursor: not-allowed;
  .el-icon-lock {
    color: #c0c4cc;
  }
  span {
    color: #006dcc;
  }
}
::v-deep .el-upload-list {
  // height: 26px;
}
</style>

